import { PageDataResponse } from '@frontastic-engbers/lib';

export const getPageType = (data: PageDataResponse) => {
  const pageFolder = data.pageFolder;
  if (pageFolder?.pageFolderType === 'landingpage') {
    if (pageFolder.configuration.path === '/') {
      return 'home';
    }

    if (hasProductListTastic(data)) {
      return 'category';
    }
  }

  switch (pageFolder?.pageFolderType) {
    case 'frontastic/cart':
      return 'cart';
    case 'frontastic/product-detail-page':
      return 'product';
    case 'frontastic/outfit-detail-page':
      return 'outfit';
    case 'frontastic/search':
      return 'searchresults';
    case 'frontastic/search/zero-results':
      return 'nosearchresults';
    case 'frontastic/category':
      return 'category';
    default:
      return 'other';
  }
};

const hasProductListTastic = (data: PageDataResponse) => {
  return !!data.page.sections.main.layoutElements.filter(
    (layout) => !!layout.tastics.filter((tastic) => tastic.tasticType.includes('product-list-algolia')).length,
  ).length;
};
