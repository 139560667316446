import React, { useEffect, useState } from 'react';
import { IconCustom } from '../icon-custom';
import styles from './back-to-top-button.module.scss';
export const BackToTopBtn: React.FC = () => {
  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };
  const [isScrolled, setIsScrolled] = useState(false);
  const btnScrolled = isScrolled ? `${styles.backToTopBtnWrapper} ${styles.scrolled}` : `${styles.backToTopBtnWrapper}`;
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 165 && !isScrolled) {
        setIsScrolled(true);
      }
      if (scrollY <= 100 && isScrolled) {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled]);
  return <div className={btnScrolled} data-sentry-component="BackToTopBtn" data-sentry-source-file="index.tsx">
      <button className={styles.backToTopBtn} onClick={scrollToTop}>
        <IconCustom width={22} color="white" icon="ChevronUpIcon" data-sentry-element="IconCustom" data-sentry-source-file="index.tsx" />
      </button>
    </div>;
};