import { IncomingMessage, ServerResponse } from 'node:http';
import { Redirect } from 'next';
import { NextRouter } from 'next/router';
import { fetchApiHubServerSide } from '@frontastic-engbers/lib';
import { Account, GetAccountResultPicked } from '@frontastic-engbers/types/account/Account';
import { isAccountAuthPath, isPwaUser, isPwaUserPath } from './accountAuth';

type Params = {
  slug?: string[];
};

type SSRRedirectParams = {
  req: IncomingMessage;
  res: ServerResponse<IncomingMessage>;
  params: Params;
};

type RedirectParams = {
  loggedIn: boolean;
  account: Account;
  url: string;
  loaded: boolean;
  router: NextRouter;
};

type RedirectResult = {
  redirect: Redirect;
};

const cancelRouting = (router: NextRouter, url: string) => {
  // See https://github.com/zeit/next.js/issues/2476
  router.events.emit('routeChangeError');
  router.push(url);
  throw `routeChange aborted. This error can be safely ignored - https://github.com/zeit/next.js/issues/2476.`;
};

export const routeChangeRedirect = ({ loaded, loggedIn, url, account, router }: RedirectParams) => {
  if (loaded) {
    if (!loggedIn && isAccountAuthPath(url)) {
      cancelRouting(router, '/login');
    }

    if (loggedIn && isPwaUserPath(url) && !isPwaUser(account)) {
      cancelRouting(router, '/mein-konto');
    }
  }
};

export const createRedirect = (destination: string, permanent = false) => ({
  redirect: {
    destination,
    statusCode: permanent ? 301 : 302,
  } as Redirect,
});

export const getUrlFromParams = (params: Params) => {
  const slug = params.slug?.join('/') || '';

  return `/${slug !== 'index' ? slug : ''}`;
};

export const getSSRRedirect = async ({ req, res, params }: SSRRedirectParams): Promise<RedirectResult | null> => {
  const url = getUrlFromParams(params);

  if (isAccountAuthPath(url)) {
    const { loggedIn, account } = (await fetchApiHubServerSide('/action/account/getAccount', {
      req,
      res,
    })) as GetAccountResultPicked;

    if (!loggedIn) {
      return createRedirect('/login');
    }

    if (loggedIn && isPwaUserPath(url) && account && !isPwaUser(account)) {
      return createRedirect('/mein-konto');
    }
  }

  return null;
};

export const getRedirectForUrl = async ({
  url,
  req,
  res,
}: {
  url: string;
  req: IncomingMessage;
  res: ServerResponse<IncomingMessage>;
}) => {
  const specialRedirects = (await fetchApiHubServerSide('/action/config/getRedirects', {
    req,
    res,
  })) as Record<string, string>;

  const redirect = specialRedirects[url];

  if (redirect) {
    return createRedirect(redirect);
  }

  return null;
};
